import React from 'react'
import ContentLoader from 'react-content-loader'

export const SensorChartButtonLoader = (): React.JSX.Element => {
    return (
        <figure className="m-2">
            <ContentLoader
                speed={2}
                width="5rem"
                height={40}
                viewBox="0 0 5rem 40"
                backgroundColor="#1a275c"
                foregroundColor="#121f56"
            >
                <rect
                    x="0"
                    y="0"
                    rx="1.5rem"
                    ry="1.5rem"
                    width="5rem"
                    height="40"
                />
                <button />
            </ContentLoader>
        </figure>
    )
}
