import React from 'react'
import { SensorChartButtonLoader } from '../sensor-chart-button-loader/sensor-chart-button-loader.component'

export const SensorChartButtonListLoader = (): React.JSX.Element => {
    const arr = [1, 2, 3]
    return (
        <section className="flex flex-row justify-center">
            {arr?.map((val: number) => <SensorChartButtonLoader key={val} />)}
        </section>
    )
}
