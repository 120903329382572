import React from 'react'
import { SensorListItemLoader } from '../sensor-list-item-loader/sensor-list-item-loader.component'

export const SensorListLoader = (): React.JSX.Element => {
    const arr = [1, 2, 3, 4, 5]
    return (
        <section className="flex flex-row justify-center">
            {arr?.map((val: number) => <SensorListItemLoader key={val} />)}
        </section>
    )
}
