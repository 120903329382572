import React from 'react'
import ContentLoader from 'react-content-loader'

export const StationSearchLoader = (): React.JSX.Element => {
    return (
        <section className="flex flex-col justify-center">
            <ContentLoader
                className="my-2"
                speed={2}
                height={46.5}
                viewBox="0 0 600 46.5"
                backgroundColor="#1a275c"
                foregroundColor="#121f56"
            >
                <rect
                    x="0"
                    y="0"
                    rx="1.5rem"
                    ry="1.5rem"
                    width="600"
                    height="46.5"
                />
            </ContentLoader>
        </section>
    )
}
