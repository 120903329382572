import React, { useEffect, useState } from 'react'
import { useStations } from '../../components/stations/stations.hooks'
import { useSensors } from '../../components/sensors/sensors.hooks'
import { useIndex } from '../../components/air-quality-index/air-quality-index.hooks'
import { StationInfoLoader } from '../../components/station-info-loader/station-info-loader.component'
import { SensorListLoader } from '../../components/sensor-list-loader/sensor-list-loader.component'
import { SensorChartLoader } from '../../components/sensor-chart-loader/sensor-chart-loader.component'
import { geolocated, GeolocatedProps } from 'react-geolocated'
import DynamicModule from '../../components/dynamic-module'
import { StationSearchLoader } from '../../components/station-search-loader/station-search-loader.component'
import { SensorChartButtonListLoader } from '../../components/sensor-chart-button-list-loader/sensor-chart-button-list-loader.component'
import './dashboard.component.css'

const Dashboard = (props: GeolocatedProps): React.JSX.Element => {
    const { isGeolocationAvailable, isGeolocationEnabled, coords } = props
    const [coordinates, setCoordinates] = useState<GeolocationCoordinates>()

    useEffect(() => {
        if (isGeolocationAvailable && isGeolocationEnabled) {
            setCoordinates(coords)
        }
    }, [isGeolocationAvailable, isGeolocationEnabled, coords])

    const [selectedStationId, setSelectedStationId] = useState<number | null>(
        null
    )
    const [selectedSensorId, setSelectedSensorId] = useState<number>(0)

    const { selectedStation, stations } = useStations(
        coordinates,
        selectedStationId
    )
    const { selectedSensor, sensors } = useSensors(
        selectedStation,
        selectedSensorId
    )
    const { airQualityIndex } = useIndex(selectedStation)

    return (
        <figure>
            <DynamicModule
                placeholder={<StationSearchLoader />}
                component={() =>
                    import(
                        '../../components/station-search/station-search.component'
                    )
                }
                stations={stations}
                selectedStation={selectedStation}
                setSelectedStationId={setSelectedStationId}
            />
            <DynamicModule
                placeholder={<StationInfoLoader />}
                component={() =>
                    import(
                        '../../components/station-info/station-info.component'
                    )
                }
                selectedStation={selectedStation}
                index={airQualityIndex}
            />
            <DynamicModule
                placeholder={<SensorListLoader />}
                component={() =>
                    import('../../components/sensor-list/sensor-list.component')
                }
                sensors={sensors}
            />
            <DynamicModule
                placeholder={<SensorChartLoader />}
                component={() =>
                    import(
                        '../../components/sensor-chart/sensor-chart.component'
                    )
                }
                selectedSensor={selectedSensor}
            />
            <DynamicModule
                placeholder={<SensorChartButtonListLoader />}
                component={() =>
                    import(
                        '../../components/sensor-chart-button-list/sensor-chart-button-list.component'
                    )
                }
                sensors={sensors}
                setSelectedSensorId={setSelectedSensorId}
            />
        </figure>
    )
}

export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
    isOptimisticGeolocationEnabled: true,
})(Dashboard)
