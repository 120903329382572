import React from 'react'
import Dashboard from './containers/dashboard/dashboard.component'
import { Analytics } from '@vercel/analytics/react'
import { SpeedInsights } from '@vercel/speed-insights/react'

function App(): React.JSX.Element {
    return (
        <div className="text-center">
            <Dashboard />
            <Analytics />
            <SpeedInsights />
        </div>
    )
}

export default App
