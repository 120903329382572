import React from 'react'
import ContentLoader from 'react-content-loader'

export const StationInfoLoader = () => {
    return (
        <section className="flex flex-col justify-center">
            <ContentLoader
                className="my-2"
                speed={2}
                height="20"
                viewBox="0 0 270 20"
                backgroundColor="#1a275c"
                foregroundColor="#121f56"
            >
                <rect x="0" y="0" rx="5" ry="5" width="270" height="20" />
            </ContentLoader>
        </section>
    )
}
